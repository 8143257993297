.subjectpage-cardWrapper {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.subjectpage-card {
  width: 400px;
  flex-grow: 1;
}

.subject-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

.subject-description {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: -5px
}
