.course-container {
    cursor: pointer;
    margin: 5px;
    border-radius: 10px !important;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1) !important;
    width: 400px;
}

@media only screen and (max-width: 1200px) {
    .course-container {
        width: 100%;
    }
}
