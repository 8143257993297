body {
  margin: 0;
}
* {
  font-family: 'Poppins';
}

:root {
  --primary-color: #0078D4; /* Azure Blue */
  --secondary-color: #F3F2F1; /* Light Gray */
  --accent-color: #00A3E0; /* Sky Blue */
  --text-color: #333333; /* Dark Gray */
  --background-color: #FFFFFF; /* White */
  --border-color: #E0E0E0; /* Light Border Gray */
  --hover-color: #005A9E; /* Darker Azure Blue for hover effects */
}

html, body {
  height: 100%; /* Set full height */
  margin: 0; /* Remove default margin */
  overflow: hidden; /* Prevent body scrolling */
}

#root {
  height: 100%; /* Ensure root has full height */
}

/* Custom Scrollbar Styles */

/* For Webkit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 6px; /* Thinner width of the scrollbar */
  height: 6px; /* Thinner height for horizontal scrolling */
}

/* Track */
::-webkit-scrollbar-track {
  background: #e6e6e6; /* Background of the scrollbar track (slightly darker than white) */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #cccccc; /* Color of the scrollbar handle (light gray) */
  border-radius: 10px; /* More rounded corners for the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3; /* Darker gray color of the handle on hover */
}

/* For Firefox */
* {
  scrollbar-width: thin; /* Use a thin scrollbar */
  scrollbar-color: #cccccc #e6e6e6; /* Handle color and track color */
}

/* Optional: Style the scrollbars for IE and Edge (not fully customizable) */
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  * {
      scrollbar-color: #cccccc #e6e6e6; /* Handle color and track color */
      scrollbar-width: thin; /* Use a thin scrollbar */
  }
}
